import * as Sentry from "@sentry/gatsby";
import { useActions, useReduxState } from "re-reduced";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import GateDropdown from "@components/scanner/GateDropdown";
import actions from "@state/actions";
import * as selectors from "@state/selectors";
import { colorsRGB } from "@util/constants";
import { useQRScanner } from "@util/scanner";
import { Container, P } from "@util/standard";
import ResponseModal from "./responseModal";
import ScanDirectionDropdown from "./scanDirectionDropdown";
import { QRCodeScanner } from "@components/shared/sub";
import { useTelemetry } from "@util/telemertry";
import { parseQRPayload } from "@util/parseQRPayload";

const Wrapper = styled.div`
  overflow: hidden;
  width: 95%;
  margin: auto;
  position: relative;
`;

const Title = styled(P)`
  color: ${colorsRGB.white(0.5)};
  font-family: bold;
  margin: 5px 0;
`;

// const SecondaryText = styled(P)`
//   color: ${colorsRGB.white(0.5)};
//   font-size: 14px;
//   margin: 0;
// `

const EventContainer = styled.div`
  position: relative;
  margin: 4px 0;
`;

const Scanner = () => {
  if (typeof window == "undefined") {
    return null;
  }
  const [modalVisible, setModalVisible] = useState(false);

  const { status, errorMsg, ticket, event } = useReduxState({
    event: selectors.getSelectedEvent,
    status: selectors.getScannedTicketStatus,
    errorMsg: selectors.getScannedTicketErrorMsg,
    ticket: selectors.getScannedTicketTicket,
  });

  const {
    updateRoute,
    scanTicket,
    clearScanTicket,
    setScannedTicketError,
  } = useActions(actions);

  const videoRef = useRef<HTMLVideoElement>();
  const scanner = useQRScanner(videoRef.current);
  const track = useTelemetry();

  useEffect(() => {
    updateRoute("scanner");
  }, []);

  useEffect(() => {
    if (event) {
      Sentry.setTag("event", event.id);
      Sentry.setTag("event-name", event.title);
    }
  }, [event]);

  // error message
  useEffect(() => {
    if (errorMsg) {
      setModalVisible(true);
    }
  }, [errorMsg]);

  // ticket loaded
  useEffect(() => {
    if (ticket && !modalVisible) {
      setModalVisible(true);
    }
  }, [ticket, modalVisible]);

  // scanned
  useEffect(() => {
    if (scanner.scanResult && !modalVisible) {
      try {
        // Payload may be a ticket number or a URL containing a ticket number at the end.
        // parseQRPayload will extract just the ticket number.
        const ticketNumber = parseQRPayload(scanner.scanResult);
        track("Scan result", { ticketNumber });
        scanner.pause();
        setModalVisible(true);
        scanTicket({ ticketNumber });
      } catch (error) {
        setScannedTicketError("Invalid QR code");
      }
    }
  }, [scanner.scanResult]);

  const handleModalClose = () => {
    scanner.setScanResult(undefined);
    scanner.resume();
    setModalVisible(false);
    clearScanTicket();
  };

  return (
    <Wrapper>
      <Container flexDirection="column" margin="0">
        <QRCodeScanner ready={scanner.isReady} videoRef={videoRef} />

        <EventContainer>
          <P noMargin>{event?.title}</P>
        </EventContainer>

        <Container flexDirection="column" width="100%" margin="0 0 8px 0">
          <Title>GATE</Title>
          <GateDropdown />
        </Container>
        <Container flexDirection="column" width="auto">
          <Title>SCAN MODE</Title>
          <ScanDirectionDropdown />
        </Container>
      </Container>

      <ResponseModal
        status={status!}
        errorMsg={errorMsg}
        ticket={ticket}
        handleModalClose={handleModalClose}
        modalVisible={modalVisible}
      />
    </Wrapper>
  );
};

export default Scanner;
