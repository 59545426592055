export const parseQRPayload = (payload: string) => {
  if (!payload.includes("ticketNumber")) {
    return payload;
  }
  
  try {
    const url = new URL(payload);
    const ticketNumber = url.searchParams.get("ticketNumber");
    if (ticketNumber) {
      return ticketNumber;
    }

    throw new Error("No ticket number found in URL");
  } catch (error) {
    console.error(error);
    throw new Error("Invalid QR code");

  }
};
